import { Utils } from "@shared/helper/utils";
import { ServiceLocator } from "./locater.service";
import { ConfigService } from "./config.service";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CrudOperations } from "../interfaces/crud.interface";
import { BehaviorSubject, Observable } from "rxjs";

export abstract class AbstractRestService<T> implements CrudOperations<T> {
  get apiUrl() {
    // if (environment.production) return this.configService.config.apiUrl;
    return environment.apiUrl;
  }
  protected configService: ConfigService;
  protected _http: HttpClient;

  public loading$ = new BehaviorSubject<boolean>(true);
  private requests: number = 0;

  constructor(public actionUrl: string) {
    const injector = ServiceLocator.getInjector();
    this._http = injector.get(HttpClient);

    if (environment.production) {
      this.configService = injector.get(ConfigService);
    }

    this.loading$.next(false);

  }

  postWithFormData(actionName, formData: any, options: any): any {
    this.updateRequest(1);
    return this._http.post<T>(
      `${this.apiUrl}${this.actionUrl}/${actionName}`,
      formData,
      options
    );
  }

  post(model: T): Promise<T> {

    this.updateRequest(1);
    return this._http
      .post<T>(`${this.apiUrl}${this.actionUrl}`, model)
      .toPromise()
      .finally(() => this.updateRequest(-1));
  }

  put(model: T): Promise<T> {
    this.updateRequest(1);
    return this._http
      .put<T>(`${this.apiUrl}${this.actionUrl}`, model)
      .toPromise()
      .finally(() => this.updateRequest(-1));
  }

  getById(id: 0): Promise<T> {
    this.updateRequest(1);
    return this._http
      .get<T>(`${this.apiUrl}${this.actionUrl}/${id}`)
      .toPromise()
      .finally(() => this.updateRequest(-1));
  }

  get(): Promise<T[]> {
    this.updateRequest(1);
    return this._http
      .get<T[]>(`${this.apiUrl}${this.actionUrl}`)
      .toPromise()
      .finally(() => this.updateRequest(-1));
  }

  getByUrl(url: string): Promise<T[]> {
    this.updateRequest(1);
    return this._http
      .get<T[]>(`${url}`)
      .toPromise()
      .finally(() => this.updateRequest(-1));
  }

  delete(id: any): Promise<any> {
    return this._http
      .delete<any>(`${this.apiUrl}${this.actionUrl}/${id}`)
      .toPromise();
  }

  getByIdAndAction(actionName, id: 0): Promise<T> {
    this.updateRequest(1);
    return this._http
      .get<T>(`${this.apiUrl}${this.actionUrl}/${actionName}/${id}`)
      .toPromise()
      .finally(() => this.updateRequest(-1));
  }

  getAnyWithAction(actionName): Promise<any> {
    this.updateRequest(1);
    return this._http
      .get<any>(`${this.apiUrl}${this.actionUrl}/${actionName}`)
      .toPromise()
      .finally(() => this.updateRequest(-1));
  }
  // getCountry() {
  //   let res = {"CountryCode":"LB"};
  //   return res;
  // }
  getAllWithAction(actionName): Promise<T[]> {

    this.updateRequest(1);
    return this._http
      .get<T[]>(`${this.apiUrl}${this.actionUrl}/${actionName}`)
      .toPromise()
      .finally(() => this.updateRequest(-1));
  }

  getWithAction(actionName, id): Promise<T[]> {
    this.updateRequest(1);
    return this._http
      .get<T[]>(`${this.apiUrl}${this.actionUrl}/${actionName}/${id}`)
      .toPromise()
      .finally(() => this.updateRequest(-1));
  }
  getWithActionQuery(actionName, obj): Promise<T[]> {
    this.updateRequest(1);
    return this._http
      .get<T[]>(`${this.apiUrl}${this.actionUrl}/${actionName}`, { params: obj })
      .toPromise()
      .finally(() => this.updateRequest(-1));
  }

  getWithActionAndMultipleParams(actionName, params: any[]): Promise<T[]> {
    let actionParams: any = "";
    if (params.length > 0) {
      actionParams = params.join("/");
    }
    this.updateRequest(1);
    return this._http
      .get<T[]>(`${this.apiUrl}${this.actionUrl}/${actionName}/${actionParams}`)
      .toPromise()
      .finally(() => {
        this.updateRequest(-1);
      });
  }

  posttWithAction(actionName, body: T): Promise<T[]> {
    this.updateRequest(1);
    if (body['MobileInput']) delete body['MobileInput'];
    return this._http
      .post<T[]>(`${this.apiUrl}${this.actionUrl}/${actionName}`, body)
      .toPromise()
      .finally(() => this.updateRequest(-1));
  }
  posttWithAnyAction(actionName, body: any): Promise<any> {
    this.updateRequest(1);
    const headers = new HttpHeaders().set(
      "Content-Type",
      "application/json; charset=utf-8"
    );
    return this._http
      .post<any>(`${this.apiUrl}${this.actionUrl}/${actionName}`, body, {
        headers: headers,
      })
      .toPromise()
      .finally(() => this.updateRequest(-1));
  }

  postWithActionAndParameters(
    actionName: string,
    paramsName: string[],
    paramsValue: any[]
  ) {
    const formData = new FormData();
    if (paramsName.length > 0 && paramsValue.length > 0) {
      for (let i = 0; i < paramsValue.length; i++) {
        formData.append(paramsName[i], paramsValue[i]);
      }
    }
    this.updateRequest(1);
    return this._http
      .post<any>(`${this.apiUrl}${this.actionUrl}/${actionName}`, formData)
      .toPromise()
      .finally(() => this.updateRequest(-1));
  }

  putWithAction(actionName, model: T): Promise<T> {
    this.updateRequest(1);
    return this._http
      .put<T>(`${this.apiUrl}${this.actionUrl}/${actionName}`, model)
      .toPromise()
      .finally(() => this.updateRequest(-1));
  }

  deleteWithAction(actionName, id: any): Promise<any> {
    this.updateRequest(1);
    return this._http
      .delete<any>(`${this.apiUrl}${this.actionUrl}/${actionName}/${id}`)
      .toPromise()
      .finally(() => this.updateRequest(-1));
  }

  updateRequest(item) {
    this.requests += item;
    this.loading$.next(this.requests > 0);
  }

  getLoading(): Observable<boolean> {
    return this.loading$;
  }
  ///////////////////
  getAllLookUpData(): Promise<any> {
    this.updateRequest(1);
    return this._http
      .get<any>(`${this.apiUrl}` + 'Lookup/AllLookupList')
      .toPromise()
      .finally(() => this.updateRequest(-1));
  }

  _getAccountDocuments() {
    return this._http
      .get<any>(`${this.apiUrl}` + 'IdentificationDocument/GetIdentificationDocuments', { params: { withAttachment: true } })
  }

  submitTest(fd) {
    this.updateRequest(1);
    return this._http
      .post<any>(`${this.apiUrl}` + 'IdentificationDocument/SaveIdentificationDocumentsWithFiles', fd)
      .toPromise()
      .finally(() => this.updateRequest(-1));
  }
}
